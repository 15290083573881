import React, { Component } from 'react'
import { Col, Row ,Card,} from 'react-bootstrap'
import { collection,getDocs } from "firebase/firestore";
import db from '../firebase'
import { InitialState } from '../util/consts';
import NoImg from '../assets/noimg.png'

export default class Marketplace extends Component {
    constructor(props){
        super(props)
        console.log(props)
        this.state = InitialState.Account
    }
     
      async componentDidMount() {
       this.load()    
      }

      putOnstate(){
        console.log(this.state.articule_box)
        if(this.state.articule_box==='undefined') return
        
        this.setState({        
          dataLoad: true
        })
        console.log( this.state.dataLoad) 
        console.log(this.state.articule_box)
        this.state.articule_box.values.map((inside)=>{
          console.log(inside)
        })

      }

    getDataStore(getUserData){
         getUserData._snapshot.docChanges.map((inside)=>{   
        try{
             if(inside.doc.data.value.mapValue.fields.levelUsuario.stringValue==='basico'){             
                 console.log(inside.doc.data.value.mapValue.fields.metaDataAccount.arrayValue)

                 this.setState({
                    articule_box: inside.doc.data.value.mapValue.fields.metaDataAccount.arrayValue,         
                  })               

                  console.log(this.state.articule_box)

            }
           

        }catch(e){
           // console.log(e)
        }
        }) 
        this.putOnstate()

    }

    async load(){
        const metaDataUser = collection(db,'_address_web3')
        const getUserData = await getDocs(metaDataUser)  
        this.getDataStore(getUserData)
       
    }




    
  render() {
    return (
      <div className='back-50-res w-100 b-inv' >
        <div>
           
       
              <Row>
                {this.state.dataLoad === true ? <>
                {this.state.articule_box.values.map((insideBox,i)=>
                                
                <Col  key={i}>
                  <div className="glass-effect" key={i}>
                    <img  className='img-card' src={insideBox.mapValue.fields.articulos_index.mapValue.fields.id.mapValue.fields.img.stringValue} width={'100%'} key={i}/>
                  </div>
                  </Col>
                  )}                
                </>:<> 
                <Col >
                  <img src={NoImg} width={'100%'}/>
                </Col></>}               
              </Row>              
              

        </div>

      </div>
    )
  }
}
