import React, { Component } from 'react'
import './App.css'
import { Web3Instance } from './util/web3Instance';
import { isConnected } from './util/funtions'
import { InitialState } from './util/consts';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import Account from './componens/Account'
import Error404 from './componens/reload/404'
import SwapMain from './componens/SwapMain';
import ConnectButton from './componens/ConnectButton'
import Marketplace from './componens/Marketplace';
import RifaMaker from './componens/RifaMaker';


export default class RoutingApp extends Component {

constructor(props) {
    super(props); 
      this.state = InitialState.App
      this.nowConnect = false    
  } 
  
  async componentDidMount(){
    this._web3Instance = await new Web3Instance().init()
  } 

  async connect() {
    await this._web3Instance.connect()
    this.checkConnection(false)
    this.nowConnect = true
  } 
  
  disconnect() {
    this._web3Instance.disconnect()
    this.resetState()
  }

  resetState() {
    this.setState(InitialState.App)
  }

async checkConnection(connect) {
  if (isConnected()) {
      if (connect)
          await this._web3Instance.connect()
          console.log(this._web3Instance)
          this.load()
        } 
    }


  displayedAccount() {
    const account = this.state.account
    return `${account.slice(0, 4)}...${account.slice(-4)}`
  }

  async load() {
    this.setState({
        account: this._web3Instance.getAccount()
    }, () => {
        this.setState({
            displayAccount: this.displayedAccount()
        })
    })
  }



  render() {
    return (
      <>
        <div className='App-header back-50-res backcity' > 
         <BrowserRouter>      
               <Routes>
                <Route path='/' element={<App/>}/>
                <Route path='/swapcoins' element={<SwapMain/>}/>
                <Route path='/perfil' element={<Account />} />
                <Route path='/test' element={<ConnectButton />} />
                <Route path='/marketplace/:id' element={<Marketplace />} />
                <Route path='/rifas' element={<RifaMaker/>} />
                <Route element={<Error404/> }/>           
            </Routes>
         </BrowserRouter>         
       </div>   
      </>    
    )
  }
}

