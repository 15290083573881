import React, { useState } from 'react'
import {Button} from 'react-bootstrap'

export default function ConnectButton() {
    const [buttonTxt,setButtonTxt] = useState('Wallet')
    const [accountCustumer,setAccountCustumer] = useState(null)

    const connectWallet = ()=>{
        if(window.ethereum && window.ethereum.isMetaMask){
            window.ethereum.request({method: 'eth_requestAccounts'})
            .then(result => {
                setAccountCustumer(result[0])
            })
            .catch(error =>{
                setButtonTxt(error.message)
            })
        }else{
            setButtonTxt('No tiene metamask')
        }
    }
  return (

    <div><Button onClick={()=>{connectWallet()}}>{buttonTxt}{accountCustumer}</Button></div>
  )
}