import React, { Component } from 'react'
import { Web3Instance } from '../util/web3Instance'
import { isConnected } from '../util/funtions'
import { collection,getDocs,getDoc,setDoc,doc,arrayUnion,updateDoc } from "firebase/firestore";
import { getDownloadURL,ref,uploadBytes,uploadBytesResumable} from 'firebase/storage'
import db from '../firebase'
import { storage } from '../firebase'
import { InitialState } from '../util/consts';
import { Button, Card, Image ,Col,Row,Modal, FormControl, InputGroup, Container} from 'react-bootstrap'
import { message } from 'antd';

export default class StoreAccount extends Component {
    constructor(props){
        super(props)
        this.state = InitialState.InitialStoreAccount
        }
        
        checkConnection() {
          this.connect()
          if (isConnected()) {
            this.connect()
        } else{
          message.warning({ content: `Necesitas conectar tu billetera a maibaik`, key: this.state.account, duration: 3 })   
          setTimeout(()=> window.location = '/' ,2900)  
       }
      }
     
      async componentDidMount() {
          this._web3Instance = await new Web3Instance().init()
          this.checkConnection()
      }
     
      async connect() {
          this._web3Instance = await new Web3Instance().init()
          await this._web3Instance.connect()      
      }

    getImgTitle(){
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
      }


      getUrlImg(){
        const loadResurses = ()=>{
          getDownloadURL(ref(storage, 'thumbs/Preview/'+this.state.token_key_dapp+'/preview.jpg'))
               .then((url) => {          
                  this.state.img_url=url
                  this.upFunctionServer()
               })
               .catch((error) => {
                 //console.log(error)
                });
         }
       loadResurses() 
      }

      async upFunctionServer(){  
        const dbReference =  doc(db,'_address_web3',this._web3Instance.account);
        const metaMaker = {
          metaStoreAccount:{
            nameStore:this.state.nameStore, 
            imgUrl:this.state.img_url,
            contactStore:this.state.contactStore,
            streetStore:this.state.streetStore,
            categoryStore:this.state.categoryStore,
            nameM:this.state.nameM
                 }
          } 
        try{    
            await updateDoc(dbReference,metaMaker)  
                }
          catch(e){
             // console.log(e)
          }    
       }
      
       


      async upToServer(){
        if(this.state.itemImg===undefined||this.state.nameStore===undefined||this.state.contactStore===undefined||this.state.streetStore===undefined||this.state.categoryStore===undefined||this.state.nameM===undefined) {
          console.log('fail')
          message.error('Favor de llenar todo',3500)  
          return 
        }
        
        const newCoinMk = ()=>{
          let seedTok = ['0','1','2','3','4','5','6','7','8','9']
          let makeToken = ""
          seedTok.map(dates=>{            
              let min = 0
              let max = 9
              let rand =  (Math.random()*(max-min))+min
              makeToken += seedTok[rand.toFixed(0)]            
          })
          let seedKey = ['a','b','c','d','e','f','g','0','1','2','3','4','5','6','7','I','T','E','M']
          let makeKey = ""
          seedKey.map(dates=>{            
              let min = 0
              let max = 18
              let rand =  (Math.random()*(max-min))+min
              makeKey += seedKey[rand.toFixed(0)]  
              return makeKey                 
          })    
          this.state.token_key_dapp = makeKey   
         } 
         newCoinMk()
         let fileData = this.state.itemImg.target.files[0]
            console.log(fileData)
            const storageRef = ref(storage, 'thumbs/Preview/'+this.state.token_key_dapp+'/preview.jpg'); 
            uploadBytes(storageRef, fileData).then((snapshot) => {
               console.log(snapshot.metadata.fullPath);
                this.getUrlImg()
            })      
            console.log(this.state.img_url)  
        }


  render() {
    return (
      <>
      <div>
        <InputGroup>
             <FormControl 
                placeholder='Nombre de negocio'
                aria-label='nameStore'
                type='text'      
                onChange={(e)=>{this.state.nameStore=e.target.value}}
              />
        </InputGroup>
        <InputGroup>
             <Button  onClick={()=>this.getImgTitle()} >Logo Corp</Button>
            <FormControl
              style={{display:'none'}}
              id='itemImg'
              type='file'
              accept='image/png,image/jpeg'
              onChange={(e)=>this.state.itemImg=e}
             />
             </InputGroup> 
        <InputGroup>
             <FormControl 
                placeholder='Contacto'
                aria-label='contactStore'
                type='text'
                onChange={(e)=>{this.state.contactStore=e.target.value}}
              />
        </InputGroup>  
        <InputGroup>
             <FormControl 
                placeholder='Direccion'
                aria-label='streetStore'
                type='text'             
                onChange={(e)=>{this.state.streetStore=e.target.value}}
              />
        </InputGroup>  
        <InputGroup>
             <FormControl 
                placeholder='Categoria'
                aria-label='categoryStore'
                type='text'               
                onChange={(e)=>{this.state.categoryStore=e.target.value}}
              />
        </InputGroup>  
        <InputGroup>
             <FormControl 
                placeholder='Gerente'
                aria-label='nameM'
                type='text'               
                onChange={(e)=>{this.state.nameM=e.target.value}}
              />
        </InputGroup>          
          <Button variant="primary" onClick={()=>{this.upToServer()}}>
            Aceptar cambios
          </Button>         
      </div>
      </>
    )
  }
}
