import React, { Component } from 'react'
import NumberGame from './NumberGame'


export default class RifaMaker extends Component {
  render() {
    return (
      <div className='z-150 no-path-d'>
        <br/>
        <NumberGame key={'abe'}/>
    
      </div>
    )
  }
}
