import Card from 'antd/es/card/Card'
import React, { Component } from 'react'
import { Input, Button, message } from 'antd';
import { BsCoin } from 'react-icons/bs'
import { RiCopperCoinLine } from 'react-icons/ri'
import { InitialState } from '../util/consts';
import { Web3Instance } from '../util/web3Instance'
import TokenSaleContract from '../contracts/Factorysalebaik'
import TokenSaleFactory from '../factory/tokensalefactory'
import Baiktoken from '../contracts/BaikToken'
import TokenFactory from '../factory/tokenfactory'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { isConnected } from '../util/funtions'
import { _bnbToWei, _weiToBNB } from '../util/units'
import Maibaikover from '../assets/Text/maibaikover.png'
import Maibaiknow from '../assets/Text/maibaiknow.png'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class SwapMain extends Component {

    constructor(props) {
        super(props)
        this.state = InitialState.SwapMain
    }

    componentWillUnmount() {
        this.resetState()
    }

    disconnect() {
        this._web3Instance.disconnect()
        this.resetState()
    }

    resetState() {
        this.setState(InitialState.SwapMain)
    }

    checkConnection() {
        this.connect()
        if (isConnected()) {
            this.load()
        } else {
            message.warning({
                content: `Necesitas conectar tu billetera a maibaik`,
                key: this.state.account,
                duration: 3
            })
        }
    }

    async componentDidMount() {
        this._web3Instance = await new Web3Instance().init()
        this.checkConnection()
    }

    async connect() {
        this._web3Instance = await new Web3Instance().init()
        await this._web3Instance.connect()
    }

    async load() {
        this.setState({
            account: this._web3Instance.getAccount()
        }, async () => {
            const TokenSale = await TokenSaleContract(this._web3Instance.getProvider())
            const Token = await Baiktoken(this._web3Instance.getProvider())
            this._TokenSaleFactory = new TokenSaleFactory(TokenSale)
            this._TokenFactory = new TokenFactory(Token)
            this.setState({
                phases: (await this._TokenSaleFactory._phases()),
                phase: (await this._TokenSaleFactory._currentPhase()),
                token_symbol: (await this._TokenFactory._symbol()),
                balance: (await this._TokenSaleFactory._tokensSold()),
                total: (await this._TokenSaleFactory._totalTokens()),

            }, () => {
                if (this.state.phase) {
                    console.log(this.state.total)
                    console.log(this.state.balance)
                    this.setState({
                        percentPhase: (this.state.phase.phase - 1) * 10.4
                    })
                    this.setState({
                        toPorcent : (this.state.balance / this.state.total) 
                    })
                    console.log(this.state.balance / this.state.total)
                }
            })
        })
    }

    getPercentage() {
        return Math.abs((this.state.phase.total / 50000) - 1) * 100
    }

    buyTokens() {
        this.setState({
            isModalVisible: true,
            input_bnb: 0,
            input_token: 0
        })
    }

    async __callBuyTokens() {
        const hideLoad = message.loading(`Comprando ${this.state.input_token} BKY...`, 0)
        if (this.state.input_bnb !== 0 && this.state.input_token !== 0) {
            try {
                await this._TokenSaleFactory._buy(
                    this.state.account,
                    this.state.input_bnb,
                    this.state.input_token
                )
                this.setState({
                    isModalVisible: false,
                    phases: (await this._TokenSaleFactory._phases()),
                    phase: (await this._TokenSaleFactory._currentPhase())
                }, () => {
                    hideLoad()
                    message.success({ content: `Tokens comprados correctamente!`, key: this.state.account, duration: 2 })
                    this.load()
                })
            } catch (err) {
                hideLoad()
                message.error({ content: `Ha ocurrido un error`, key: this.state.account, duration: 2 })
                console.log(err)
            }
        } else {
            hideLoad()
            message.error({ content: `Debes poner un valor de compra`, key: this.state.account, duration: 2 })
        }
    }

    onTokenChange = e => {
        console.log(this.state.phase.price)
        if (this.tokOff) {
            this.connect()
        }
        this.setState({
            input_token: e.target.value
        }, () => {
            this.setState({
                input_bnb: _weiToBNB(this.state.input_token * this.state.phase.price)
            })
        })
    }

    onBNBChange = e => {
        console.log(this.state.phase.price)
        this.setState({
            input_bnb: e.target.value
        }, () => {
            this.setState({
                input_token: _bnbToWei(this.state.input_bnb) / this.state.phase.price
            })
        })
    }


    render() {
        return (
            <div className='no-path-d' >
                <Card title="Swap Baik / BNB" className='backcard no-abc z-15' >
                    {this.state.headCardSell}
                    <br />
                    <Input placeholder={this.state.input_bnb} value={this.state.input_bnb} prefix={<RiCopperCoinLine />} suffix={'BNB'} onChange={(e) => this.onBNBChange(e)} />
                    <br />
                    <br />
                    <br />
                    <Input placeholder={this.state.input_token} value={this.state.input_token} prefix={<BsCoin />} suffix={'BKY'} onChange={(e) => this.onTokenChange(e)} />
                    <br />
                    <br />
                    <Button block onClick={() => this.__callBuyTokens()} disabled={false}>Comprar</Button>
                </Card>
                <br />
                <div className='w-100'> 
                    <OverlayTrigger  
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">
                            Vendidos {(this.state.balance / this.state.total )*100} %
                            <br/>
                            Necesarios para abrir
                            25%
                            </Tooltip>}>
                       <ProgressBar>  
                          <ProgressBar now={(this.state.balance / this.state.total )*100} animated label={`${this.state.balance}`} />
                          <ProgressBar now={25} striped variant="warning" animated label={`Para abrir`} />                   
                          <ProgressBar now={100} striped variant="success" animated label={`Disponible`} />  
                       </ProgressBar>     
                    </OverlayTrigger>
                    
                </div>
            </div>
        )
    }
}
