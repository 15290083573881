import React, { Component } from 'react'
import { FiTwitter } from 'react-icons/fi'
import { CiFacebook } from 'react-icons/ci'
import { RxDiscordLogo } from 'react-icons/rx'
import { Button, Card, Image } from 'react-bootstrap'
import metaMaskIco from '../assets/metamask.png'
import auditedByRug from '../assets/rugdoc.png'
import Logo from '../assets/logo.png'
import { InitialState } from '../util/consts';
import { Web3Instance } from '../util/web3Instance'
import TokenSaleContract from '../contracts/Factorysalebaik'
import TokenSaleFactory from '../factory/tokensalefactory'
import { isConnected } from '../util/funtions'



export default class Home extends Component {

  constructor(props) {
    super(props)
    this.state = InitialState.SwapMain
    this.balanceNow = '0'
  }


  checkConnection() {
    this.connect()
    this.connectAddress()
    if (isConnected()) {
      this.load()
    }
  }

  async componentDidMount() {
    this._web3Instance = await new Web3Instance().init()
    this.checkConnection()
  }

  async connect() {
    this._web3Instance = await new Web3Instance().init()
    await this._web3Instance.connect()
    console.log(this.balanceNow)
  }


  async connectAddress() {
    console.log(this._web3Instance.getAccount())
    //this.load() 
    if (isConnected()) {
      this.load()
    }
  }

  async load() {
    this.setState({
      account: this._web3Instance.getAccount()
    }, async () => {
      const TokenSale = await TokenSaleContract(this._web3Instance.getProvider())
      this._TokenSaleFactory = new TokenSaleFactory(TokenSale)
      this.setState({

        balance: (await this._TokenSaleFactory._tokens(this.state.account))
      })
      console.log(this.state.balance)
    })
  }

  addContractToMetaMask(contractAddress) {
    // Check if MetaMask is installed and available
    if (typeof window.ethereum === 'undefined') {
      alert('Please install MetaMask to use this feature.');
      return;
    }

    // Request permission to access the user's MetaMask accounts


    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
        // Add the smart contract to MetaMask
        window.ethereum
          .request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20', // Adjust this based on the type of your smart contract
              options: {
                address: '0xC00978BcA10a19340d98e250b362Ca554Cb52B2e',
                symbol: 'BKY', // Replace with the token symbol
                decimals: 18, // Replace with the token decimals
                image: 'https://firebasestorage.googleapis.com/v0/b/service-meisoft-db-x8t89.appspot.com/o/wheel.png?alt=media&token=765ccabb-6ef4-41b2-87d2-3159973807ef' // Replace with the token logo URL
              }
            }
          })
          .then(() => {
            alert('Smart contract added to MetaMask successfully!');
          })
          .catch((error) => {
            console.error(error);
            alert('Failed to add smart contract to MetaMask.');
          });
      })
      .catch((error) => {
        console.error(error);
        alert('Failed to connect to MetaMask.');
      });
  }

  render() {
    return (
      <div className='midle-text' style={{ paddingLeft: '25%', zIndex: '250!important' }}>
        <section>
          <img id='logo-maibaik' src={Logo} width='50%' className='p-absolute z-150 logo-class' />
          <img onClick={() => { window.location = 'https://rugdoc.io/project/mai-baik/' }} id='logo-rugdoc' src={auditedByRug} className='p-absolute z-150 logo-rug' />
          <Image onClick={() => { this.addContractToMetaMask() }} id='logo-metamask' src={metaMaskIco} className='p-absolute z-150 logo-metamask' />
        </section>
        <section className='z-150 p-absolute social-btn'>
          <div>
            <a href='https://www.facebook.com/profile.php?id=100092350171284' className='color-set-brow'>
              <CiFacebook /></a>
            <span> </span>
            <a href='https://twitter.com/mai_baik' className='color-set-brow'>
              <FiTwitter /></a>
            <span> </span>
            <a href='https://discord.gg/ftgzyuYYT3' className='color-set-brow'>
              <RxDiscordLogo /></a>
            <span> </span>
            <br />
            <br />
            <div><p className='neon-text'></p></div></div>
        </section>
      </div>
    )
  }
}



