import React, { Component } from 'react'
import {FiTwitter}  from 'react-icons/fi'
import {CiFacebook} from 'react-icons/ci'
import {RxDiscordLogo} from 'react-icons/rx'
import { Web3Instance } from '../util/web3Instance'
import { isConnected } from '../util/funtions'
import { Button, Card, Image ,Col,Row,Modal, FormControl, InputGroup, Container} from 'react-bootstrap'
import { message } from 'antd';
import { InitialState } from '../util/consts';
import { storage } from '../firebase'
import { getDownloadURL,ref,uploadBytes,uploadBytesResumable} from 'firebase/storage'
import { updateDoc,arrayUnion,arrayRemove } from 'firebase/firestore';
import { addDoc,collection,doc,getDoc,setDoc } from 'firebase/firestore';
import db from '../firebase'
import IconBKY from '../assets/wheel.png'
import itemNuevo from '../assets/itemnuevo.png'
import Rack from '../assets/svg/rank.png'
import Warestore from '../assets/svg/warestore.png'
import { LuListPlus,LuArchiveRestore,LuArrowUpAZ ,LuTruck} from "react-icons/lu";
import NoImg from '../assets/noimg.png'
import StoreAccount from './StoreAccount'


export default class Account extends Component {

  constructor(props) {
    super(props)
    this.state = InitialState.Account
    this.setState({show:false,dataLoad:false})
  }
  
checkConnection() {
     this.connect()
     if (isConnected()) {
       this.checkServer()
   } else{
     message.warning({ content: `Necesitas conectar tu billetera a maibaik`, key: this.state.account, duration: 3 })   
     setTimeout(()=> window.location = '/' ,2900)  
  }
 }

 async componentDidMount() {
     this._web3Instance = await new Web3Instance().init()
     this.checkConnection()
 }

 async connect() {
     this._web3Instance = await new Web3Instance().init()
     await this._web3Instance.connect()      
 }

 async checkServer(){
    let maxWidth = window.innerWidth || document.documentElement.clientWidth;
    let maxHeight = window.innerHeight || document.documentElement.clientHeight;
    let elements = document.getElementsByClassName('bodega-frame');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.width = (maxWidth - 50) + 'px' ;
   
    }    
    const metaDataUser = doc(db,'_address_web3',this._web3Instance.account)
    const getUserData = await getDoc(metaDataUser)  
   
    if(getUserData.data()===undefined){
      try{
        await setDoc(doc(db,'_address_web3',this._web3Instance.account),{
        nombreUsuario:'undefined',                    
        address:this._web3Instance.account,                                        
        mailUsuario:'undefined',
        levelUsuario:'undefined',
        idAccount:'undefined',
        otherAccounts:{},
        metaDataAccount:{        
          articulos_index :{id:{nombre:'zero',img:'',detalles:'',costo:'',track_id:'',cantidad:'',box_id:'',rack_id:''}},     
        },
     })
     const dbReference =  doc(db,'_address_web3',this._web3Instance.account);
     const metaMaker = {
      metaDataAccount:arrayUnion({
         articulos_index:{ id: {nombre:'zero',img:'',detalles:'',costo:'',track_id:'',cantidad:'',box_id:'',rack_id:'',id_sk:''}},
      }),
    }
    try{    
      await updateDoc(dbReference,metaMaker)  
          }
      catch(e){
       // console.log(e)
      }                        
    }
    catch(e){
        console.log(e)
    }    
    } else{
      console.log(getUserData.data().metaDataAccount.articulos_index)
      this.setState({
          articule_box: getUserData.data().metaDataAccount,         
        })
       if(getUserData.data().metaDataAccount.articulos_index==='undefined') return
        this.setState({        
          dataLoad: true
        })
        getUserData.data().metaDataAccount.forEach(element => {
        if(element.articulos_index.id.rack_id===''){         
          this.state.haveItemWithoutBox = false
        }   
      });        
    }   
 }

 async upFunctionServer(){  
  const dbReference =  doc(db,'_address_web3',this._web3Instance.account);
  const metaMaker = {
    metaDataAccount:arrayUnion({
      articulos_index:{ id: {
        nombre:this.state.nombreItem,
        img:this.state.img_url,
        detalles:this.state.detailsItem,
        costo:this.state.priceItem,
        track_id:'',
        cantidad:this.state.amountItem,
        box_id:'',
        rack_id:'',
        id_sk:this.state.token_key_dapp}},
    }),
  }
  try{    
      await updateDoc(dbReference,metaMaker)  
          }
    catch(e){
       // console.log(e)
    }    
 }

 getUrlImg(){
  const loadResurses = ()=>{
    getDownloadURL(ref(storage, 'thumbs/Preview/'+this.state.token_key_dapp+'/preview.jpg'))
         .then((url) => {          
            this.state.img_url=url
            this.upFunctionServer()
         })
         .catch((error) => {
           //console.log(error)
          });
   }
 loadResurses() 
}



  getImgTitle(){
    let putImg = document.querySelectorAll('#itemImg')
    putImg[0].click()
  }
 
async upToServer(){
  if(this.state.itemImg===undefined
    &&this.state.amountItem===undefined
    &&this.state.priceItem===undefined
    &&this.state.nombreItem===undefined
    &&this.state.codeItem===undefined) return 
  
  const newCoinMk = ()=>{
    let seedTok = ['0','1','2','3','4','5','6','7','8','9']
    let makeToken = ""
    seedTok.map(dates=>{            
        let min = 0
        let max = 9
        let rand =  (Math.random()*(max-min))+min
        makeToken += seedTok[rand.toFixed(0)]            
    })
    let seedKey = ['a','b','c','d','e','f','g','0','1','2','3','4','5','6','7','I','T','E','M']
    let makeKey = ""
    seedKey.map(dates=>{            
        let min = 0
        let max = 18
        let rand =  (Math.random()*(max-min))+min
        makeKey += seedKey[rand.toFixed(0)]  
        return makeKey                 
    })    
    this.state.token_key_dapp = makeKey   
   } 
   newCoinMk()
   let fileData = this.state.itemImg.target.files[0]
      console.log(fileData)
      const storageRef = ref(storage, 'thumbs/Preview/'+this.state.token_key_dapp+'/preview.jpg'); 
      uploadBytes(storageRef, fileData).then((snapshot) => {
         console.log(snapshot.metadata.fullPath);
          this.getUrlImg()
      })      
      console.log(this.state.img_url)  
  }

  async throwInBox(){
    const dbReference =  doc(db,'_address_web3',this._web3Instance.account);
    const metaMaker = {
      metaDataAccount:arrayUnion({
        articulos_index:{ id: {
          nombre:this.state.nombreItem,
          img:this.state.img_url,
          detalles:this.state.detailsItem,
          costo:this.state.priceItem,
          track_id:'',cantidad:'',box_id:'',rack_id:'',
          id_sk:this.state.token_key_dapp}},
      }),

    }
    try{    
        await updateDoc(dbReference,metaMaker)  
            }
      catch(e){
         // console.log(e)
      }   
  }


  async boxMakerId(){
    if(this.state.itemImg===undefined) return 
    const newCoinMk = ()=>{
      let seedTok = ['0','1','2','3','4','5','6','7','8','9']
      let makeToken = ""
      seedTok.map(dates=>{            
          let min = 0
          let max = 9
          let rand =  (Math.random()*(max-min))+min
          makeToken += seedTok[rand.toFixed(0)]            
      })
      let seedKey = ['a','b','c','d','e','f','g','0','1','2','3','4','5','6','7','I','T','E','M']
      let makeKey = ""
      seedKey.map(dates=>{            
          let min = 0
          let max = 18
          let rand =  (Math.random()*(max-min))+min
          makeKey += seedKey[rand.toFixed(0)]  
          return makeKey                 
      })    
      this.state.token_box_dapp = makeKey   
     } 
     newCoinMk()
     this.throwInBox()
    }

    showStoreAccount(){
      let storeDOM = document.getElementById('bodega')
      let profileDOM = document.getElementById('perfil')
      console.log(profileDOM)
      storeDOM.style.display='none'
      profileDOM.style.display='block'
     
    }

    showStoregear(){
      let storeDOM = document.getElementById('bodega')
      let profileDOM = document.getElementById('perfil')
      console.log(profileDOM)
      storeDOM.style.display='block'
      profileDOM.style.display='none'
    }




  render() {
    return (
      <>
      <div className=''>        
        <section className="frame-right nav-tab-container">
          <div className="menu-container" >
            <div  style={{height:'65%'}}>
              <ul>              
                <li onClick={()=>{this.showStoregear()}}>Bodega</li>
                <li>Racks</li>
                <li onClick={()=>{this.showStoreAccount()}}>Perfil</li>
                <li>Intercambio</li>
                <li>Marketplace</li>
              </ul>
            </div>
            
            <footer className='background-black footer-container z-15' style={{height:'30%',position:'absolute',bottom:'0px'}}>
              <div>
                <a href='https://www.facebook.com/profile.php?id=100092350171284' className='color-set-brow'>
                  <CiFacebook /></a><span> </span>
                <a href='https://twitter.com/mb_delivery' className='color-set-brow'>
                  <FiTwitter /></a><span> </span>
                <a href='https://discord.gg/ftgzyuYYT3' className='color-set-brow'>
                  <RxDiscordLogo /></a><span> </span>
              </div>
            </footer>
          </div>
        </section>
        <section className="frame-left">
          <div >         
            <section id='bodega' style={{display:'block'}}> 
             <div className='bodega-frame frames-on-account' >
             <Button className='btn-account' >
              <img width={'20%'} src={IconBKY} /> {this.state.balance} BKY</Button>
                <div>
                  <Row>
                    
                  </Row><br/>
                    <br/>
                    <div className="wrstr-clss" >                 
                      {this.state.haveItemWithoutBox===false ? <></>: <><img width={'3%'} src={itemNuevo} className='article-new'/></> }
                      <img className="warestore-bkrg " src={Warestore} width={'50%'} />
                    </div> 
                </div> 
                <footer className='footer-blck footer-container z-15 wrstr-clss' >
                  <br></br>
                  <br></br>
                  <Row>
                    <Col><span><LuListPlus/></span>
                    <br></br>
                    <Button prefix='primary'>Agregar Rack</Button></Col>
                    <Col><span><LuArchiveRestore/></span>
                    <br></br>
                    <Button prefix='primary' onClick={()=>{this.setState({isModalBox:true})}}>Empaquetar en cajas</Button></Col>
                    <Col><span><LuArrowUpAZ/></span>
                    <br></br>
                    <Button prefix='primary' onClick={()=>{this.setState({isModalOpen:true})}}>Agregar productos</Button></Col>
                    <Col><span><LuTruck/></span>
                    <br></br>
                    <Button prefix='primary'>Envios</Button></Col>
                   </Row>
                 </footer>
             </div>
            </section>    
            <section id='racks' style={{display:'none'}}> 
            </section>   
            <section id='perfil' style={{display:'none'}}> 
            <StoreAccount/>
            </section>   
            <section id='intercambio' style={{display:'none'}}> 
            </section>   
            <section id='marketplace' style={{display:'none'}}> 
            </section>   
          </div>
        </section>
      </div>
      <div className='modals-container-old-sx'>

      <Modal show={this.state.isModalOpen} onHide={()=>{this.setState({isModalOpen:false})}}>
        <Modal.Header>
          <Modal.Title>Nuevo Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>     
          <br/>    
           <InputGroup>
            <FormControl 
              placeholder='Nombre'
              aria-label='nameItem'
              type='text'            
              // value={codeMovie}          
              onChange={(e)=>{this.state.nombreItem=e.target.value}}
             />
            </InputGroup>
            <br/>
            <InputGroup>
             <Button  onClick={()=>this.getImgTitle()} >Cargar Imagen</Button>
            <FormControl
              style={{display:'none'}}
              id='itemImg'
              type='file'
              accept='image/png,image/jpeg'
              onChange={(e)=>this.state.itemImg=e}
             />
             </InputGroup>
             <br/>
             <InputGroup>
             <FormControl 
                placeholder='Detalles'
                aria-label='detailsItem'
                type='text'            
                // value={codeMovie}          
                onChange={(e)=>{this.state.detailsItem=e.target.value}}
             />
             </InputGroup>
             <br/>
             <InputGroup>
             <FormControl 
                placeholder='Precio'
                aria-label='priceItem'
                type='number'            
                // value={codeMovie}          
              onChange={(e)=>{this.state.priceItem=e.target.value}}
              />
              </InputGroup>
              <br/>
              <InputGroup>
             <FormControl 
                placeholder='Cantidad'
                aria-label='amountItem'
                type='number'            
                // value={codeMovie}          
              onChange={(e)=>{this.state.amountItem=e.target.value}}
              />
              </InputGroup>  
              <br/>
             <InputGroup>
             <FormControl 
                placeholder='Codigo'
                aria-label='codeItem'
                type='number'            
                // value={codeMovie}          
              onChange={(e)=>{this.state.codeItem=e.target.value}}
              />
              </InputGroup>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.setState({isModalOpen:false})}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={()=>{this.setState({isModalOpen:false});this.upToServer()}}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal> 


      <Modal show={this.state.isModalBox} onHide={()=>{this.setState({isModalBox:false})}}>
        <Modal.Header>
          <Modal.Title>Poner en caja</Modal.Title>
        </Modal.Header>
        <Modal.Body>     
          <br/>
          <Card>
            <Card.Body className='max-card'>
              <Row>
                {this.state.dataLoad === true ? <>
                {this.state.articule_box.map((insideBox,i)=>                
                <Col  key={i}>
                  <div className="glass-effect" key={i}>
                    <img  className='img-card' src={insideBox.articulos_index.id.img} width={'100%'} key={i}/>
                  </div>
                  </Col>
                  )}                
                </>:<> 
                <Col >
                  <img src={NoImg} width={'100%'}/>
                </Col></>}               
              </Row>              
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>              
        </Modal.Body>
        <Modal.Footer>
      
          <Button variant="primary" onClick={()=>{this.setState({isModalBox:false})}} >
           Cerrar
          </Button>
        </Modal.Footer>
      </Modal> 
      </div>
      </>
    )
  }
}

